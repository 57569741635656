const version = 'v1';

export const AgreementService = {
  AGREEMENT: `/${version}/agreement`,
};

export const AuthService = {
  EMAIL_PWD_LOGIN: `/${version}/login`, // POST call
  RESEND_OTP: `/${version}/otps`, // POST call /otp/{record_id}
  VERIFY_OTP: `/${version}/otps`, // PATCH call
  REFRESH_TOKEN: `/${version}/refresh`, // POST call /refresh/{record_id}
  REVOKE_TOKEN: `/${version}/revoke`, // POST call
  SSO_LOGIN: `/${version}/sso_login`, // POST call
};

export const AccessManagement = {
  GET_ROLES: `/${version}/roles`,
  CREATE_ROLE: `/${version}/roles`,
  GET_PERMISSIONS: `/${version}/roles`,
  PUT_PERMISSIONS: `/${version}/roles`,
  DELETE_ROLE: `/${version}/roles`,
};

export const UserManagement = {
  USERS: `/${version}/users`, // GET and POST users
  USER: (userId) => (`/${version}/users/${userId}`), // PATCH call
  GET_USER_PERMISSIONS: (userId) => (`/${version}/users/${userId}/permissions`), // GET call
  GET_AUDIT_LOGS: `/${version}/users/audit-log`, // GET audit logs
  DOWNLOAD_AUDIT_LOGS: `/${version}/users/export/audit-log`, // GET audit log file presigned url
};

export const ApplicationService = {
  GET_PRESIGNED_URLS: `/${version}/applications`, // PUT call
  GET_APPLICATIONS: `/${version}/applications`, // POST call
  GET_ATTACHMENT: (appId) => (`/${version}/applications/${appId}`), // GET call
  UPDATE_APPLICATION: (appId) => (`/${version}/applications/${appId}`), // PATCH call
  ATTACH_DOCS_TO_APP: (appId) => (`/${version}/applications/${appId}/documents`), // PUT call
  UPDATE_APPLICATION_ASSIGNEE: (appId) => (`/${version}/applications/${appId}/assignments`), // PUT call
  DELETE_SUBMISSION: (appId) => (`/${version}/applications/${appId}`), // DELETE call
  GET_PRESIGNED_URL: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/presignedurl`), // Get Call
  CONFIRM_CLASSIFICATION: (appId) => (`/${version}/applications/${appId}/documents/classification-approval`), // PATCH call
  CLF_HITL: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/classification`), // PUT call
  SUB_CLF_HITL: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/sub-classification`), // PUT call
  CARRIER_HITL: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}`), // PATCH call
  UPDATE_LOB: (appId) => (`/${version}/applications/${appId}`), // PATCH call
  DELETE_DOCUMENT: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}`), // delete call
};

// get all system calls
export const ServiceCall = {
  GET_SYSTEM_CALL: (type) => (`/${version}/system?type=${type}`),
};

// Extraction Service
export const ExtractionService = {
  VIEW_EXTRACTION: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction`), // POST call
  ADD_KEY_VALUES: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/key_values`), // POST call
  EDIT_KEY_VALUES: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/key_values`), // PUT call
  DELETE_KEY_VALUES: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/key_values`), // DELETE call
  UPDATE_KEY_VALUE_ATTR: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/key_values`), // PATCH call
  ADD_ROWS: (appId, docId, tableId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/tables/${tableId}/rows`), // POST call
  EDIT_ROWS: (appId, docId, tableId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/tables/${tableId}/rows`), // PUT call
  DELETE_ROWS: (appId, docId, tableId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/tables/${tableId}/rows`), // DELETE call
  GET_ALL_BBOXES: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/bounding-box`), // GET call
  TABLE_COLUMNS: (appId, docId, tableId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/tables/${tableId}/columns`), // POST call
  ADD_TABLE: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/tables`), // post call
  DELETE_TABLE: (appId, docId, tableId) => (`/${version}/applications/${appId}/documents/${docId}/extraction/tables/${tableId}`), // DELETE call
  EXPORT_DOCUMENT: (appId, docId, format) => (`/${version}/applications/${appId}/documents/${docId}/export/extraction?export_type=${format}`), // GET call
  SUMMARY: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction-summary`), // POST call
  EXPORT_SUMMARY: (appId, docId, format) => (`/${version}/applications/${appId}/documents/${docId}/export/extraction-summary?export_type=${format}`), // GET call
  FETCH_TABLE: (appId, docId, extHeaderId, sectionId, tableId, pageNumber, pageSize) => (`/${version}/applications/${appId}/documents/${docId}/extraction/${extHeaderId}/sections/${sectionId}/tables/${tableId}?page_number=${pageNumber}&page_size=${pageSize}`),
  DRAW_BBOX: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/draw-bbox`), // POST call
  SUMMARY_KEY_VALUES: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction-summary/key_values`), // POST, PUT, DELETE
  SEND_NOTICATION: `/${version}/notifications/email`, // POST call
  SUMMARY_ADD_TABLE: (appId, docId) => (`/${version}/applications/${appId}/documents/${docId}/extraction-summary/table`), // POST
  SUMMARY_ADD_ROW: (appId, docId, tableId) => (`/${version}/applications/${appId}/documents/${docId}/extraction-summary/table/${tableId}/rows`), // POST, PUT, DELETE
};

// Comparison Service
export const ComparisonService = {
  VIEW_COMPARISON: (appId) => (`/${version}/applications/${appId}/compare`),
  KEY_HITL_OPERATION: (appId) => (`/${version}/applications/${appId}/comparison/key_values`),
  TABLE_HITL_OPERATION: (appId) => (`/${version}/applications/${appId}/comparison/tables`),
  EXPORT_COMPARISON: (appId, format) => (`/${version}/applications/${appId}/export/comparison?export_type=${format}`), // GET call
  UPDATE_MATCHED: (appId) => (`/${version}/applications/${appId}/compare/validate`), // POST call
  UPDATE_CELLS_FOR_VALIDATION: (appId) => (`/${version}/applications/${appId}/comparison/validate`), // PATCH call
};

export const InsightsDashboardService = {
  GET_CHART_DATA: (chartType) => (`/${version}/reports/ops-dashboard?chart_type=${chartType}`), // POST call
};

// DocumentsService
export const DocumentService = {
  VIEW_DOCUMENT: (type) => (`/${version}/system/documents?document_type=${type}`), // view documents
};
