const workflowTabs = [
  {
    label: 'Extraction',
    value: 0,
    index: 0,
  },
  {
    label: 'Comparison',
    value: 1,
    index: 1,
  },
  //   {
  //     label: 'Enrichment',
  //     value: 2,
  //     index: 2,
  //   },
  //   {
  //     label: 'Validation',
  //     value: 3,
  //     index: 3,
  //   },
];

const ApplicationDashboardHeaders = [
  {
    key: 'app_status',
    value: 'App Information',
    isSortingAvailable: true,
    sorting: 'unsorted',
    width: '13.2%',
  },
  {
    key: 'sender_name',
    value: 'Sender Information',
    isSortingAvailable: true,
    sorting: 'unsorted',
    width: '12%',
  },
  {
    key: 'description',
    value: 'Description',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '9.97%',
  },
  {
    key: 'type_of_business',
    value: 'Type of Business',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '10.97%',
  },
  {
    key: 'line_of_business',
    value: 'Line of Business',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '9.97%',
  },
  {
    key: 'first_named_insured',
    value: 'First Name Insured',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '11%',
  },
  {
    key: 'ingestion_time',
    value: 'Time Ingested',
    isSortingAvailable: true,
    sorting: 'desc',
    width: '11.13%',
  },
  {
    key: 'action',
    value: 'Action',
    isSortingAvailable: false,
    sorting: 'desc',
    width: '19.5%',
  },
];

const AuditLogDashboardHeaders = [
  {
    key: 'created_at',
    value: 'Time Ingested',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '15%',
  },
  {
    key: 'audit_category',
    value: 'Audit Category',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '15%',
  },
  {
    key: 'action',
    value: 'Action',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '15%',
  },
  {
    key: 'performed_by',
    value: 'Performed By',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '25%',
  },
  {
    key: 'audit_details',
    value: 'Description',
    isSortingAvailable: false,
    sorting: 'unsorted',
    width: '40%',
  },
];

const AuditCategories = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
  APPLICATION: 'APPLICATION',
  DOCUMENT: 'DOCUMENT',
};

const AppDashboardFilters = [
  {
    name: 'status',
    displayName: 'Status',
    isTimeFilter: false,
    listName: 'appStatusList',
  },
  {
    name: 'source',
    displayName: 'Source',
    isTimeFilter: false,
    listName: 'sourceList',
  },
  {
    name: 'assignedTo',
    displayName: 'Assigned To',
    isTimeFilter: false,
    listName: 'users',
  },
  {
    name: '',
    displayName: 'Time Ingested',
    isTimeFilter: true,
    listName: 'TimeFilterOptions',
  },
];

const AttachmentHeaders = [
  {
    key: 'document_name',
    value: 'Document Name',
    width: '16.76%',
  },
  {
    key: 'carrier',
    value: 'Carrier',
    width: '10.07%',
  },
  {
    key: 'classification',
    value: 'Classification',
    width: '16%',
  },
  {
    key: 'sub_classification',
    value: 'Sub Classification',
    width: '15.84%',
  },
  {
    key: 'confidence_score',
    value: 'Confidence Score',
    width: '12%',
  },
  {
    key: 'status',
    value: 'Status',
    width: '13%',
  },
  {
    key: 'actions',
    value: 'Actions',
    width: '12%',
  },
];

const extractionScreenEntityHeader = [
  {
    key: 'key',
    value: 'Key',
  },
  {
    key: 'value',
    value: 'Value',
  },
  {
    key: 'confidence',
    value: 'Confidence',
  },
];

const bulkOperationName = {
  bulkAdd: 'Bulk Add',
  bulkDelete: 'Bulk Delete',
  bulkEdit: 'Bulk Edit',
};

const KeyBoardKeys = {
  enter: 'enter',
  escape: 'escape',
};

const OperationalMessages = {
  deleteMessage: 'Are you sure you want to delete',
};
const pageSizeOptions = [
  {
    key: '10',
    value: '10',
  },
  {
    key: '15',
    value: '15',
  },
  {
    key: '20',
    value: '20',
  },
];

const Permissions = {
  VIEW_USERS: 'View Users',
  CREATE_USER: 'Create User',
  DELETE_USER: 'Delete User',
  EDIT_USER_ACCESS: 'Edit User Access',
  VIEW_ROLES: 'View Roles',
  CREATE_ROLE: 'Create Role',
  EDIT_ROLE: 'Edit Role',
  DELETE_ROLE: 'Delete Role',
  VIEW_PERMISSIONS: 'View Permissions',
  LIST_METADATA: 'List Metadata',
  VIEW_APPLICATIONS: 'View Applications',
  EDIT_APPLICATION: 'Edit Application',
  DELETE_APPLICATION: 'Delete Application',
  CREATE_APPLICATION: 'Create Application - UI Upload',
  VIEW_DOCUMENTS: 'View Documents',
  EDIT_DOCUMENTS: 'Edit Documents',
  ASSIGN_APPLICATION_USER: 'Assign Application - User',
  ASSIGN_APPLICATION_SELF: 'Assign Application - Self',
  UNASSIGN_APPLICATION_USER: 'Unassign Application - User ',
  UNASSIGN_APPLICATION_SELF: 'Unassign Application - Self',
  EDIT_CLASSIFICATION_DETAILS: 'Edit Classification Details',
  VIEW_EXTRACTION: 'View Extractions',
  EDIT_EXTRACTION: 'Edit Extraction Data',
  VIEW_COMPARISONS: 'View Comparisons',
  EDIT_COMPARISON: 'Edit Comparison',
  VIEW_METRICS: 'View Metrics',
  GENERATE_REPORTS_ACCURACY: 'Generate Reports - Accuracy',
  VIEW_NOTIFICATIONS_APPLICATION: 'View Notifications - Application',
  EDIT_NOTIFICATION_APPLICATION: 'Edit Notification - Application',
  SEND_NOTIFICATION_EMAIL: 'Send Notification- Email',
  EXPORT_EXTRACTION: 'Export - Extraction',
  EXPORT_COMPARISON: 'Export - Comparison',
  VIEW_AGREEMENT: 'View Agreement',
  UPDATE_AGREEMENT: 'Update Agreement',
  VIEW_AUDIT_LOGS: 'View - Audit Logs',
};

const ApplicationStatus = {
  RECEIVED: 'Received',
  PROCESSING: 'Processing',
  CLASSIFICATION_UNDER_REVIEW: 'Classification Under Review',
  EXTRACTION_UNDER_REVIEW: 'Extraction Under Review',
  REVIEWED: 'Reviewed',
};

const AttachmentStatus = {
  Received: 'received',
  Classifying: 'classifying',
  Preprocessing: 'preprocessing',
  Classified: 'classified',
  Extracting: 'extracting',
  Extracted: 'extracted',
  Processed: 'processed',
  Reviewed: 'reviewed',
};

const AttachmentStatusCount = [
  {
    key: 'received',
    value: 'Received',
  },
  {
    key: 'classifying',
    value: 'Classifying',
  },
  {
    key: 'classified',
    value: 'Classified',
  },
  {
    key: 'extracting',
    value: 'Extracting',
  },
  {
    key: 'extracted',
    value: 'Extracted',
  },
];

const SilentRefreshStartStatuses = [
  'Received',
  'Classifying',
  'Extracting',
];

const SilentRefreshStopStatuses = [
  'Classified',
  'Extracted',
];

const SilentRefreshTimeout = 5000; // in milliseconds

const SilentRefreshMaxRetries = 30;

const ExportDocumentDropdownOptions = [
  { key: 'download-as', value: 'Download as' },
  { key: 'json', value: '.JSON' },
  { key: 'xlsx', value: '.XLSX' },
];

const ExportEntitiesDocumentDropdownOptions = [
  { key: 'download-as', value: 'Download as', description: '' },
  { key: 'json', value: '.JSON', description: 'Download entity level' },
  { key: 'xlsx', value: '.XLSX', description: 'Download entity level' },
];

const ExportTableDocumentDropdownOptions = [
  { key: 'download-as', value: 'Download as', description: '' },
  { key: 'json', value: '.JSON', description: 'Download table level' },
  { key: 'xlsx', value: '.XLSX', description: 'Download table level' },
];

const ExportSummaryDocumentDropdownOptions = [
  { key: 'download-as', value: 'Download as', description: '' },
  { key: 'xlsx', value: '.XLSX', description: 'Eligibility Rule Data Extraction File' },
  { key: 'json', value: '.JSON', description: 'Download summary level' },
];

const ExportComparisonDropdownOptions = [
  { key: 'download-as', value: 'Download as', description: '' },
  { key: 'json', value: '.JSON', description: 'Download comparison level' },
  { key: 'xlsx', value: '.XLSX', description: 'Download comparison level' },
];

const TimeFilterOptions = [
  {
    key: 'today',
    value: 'Today',
  },
  {
    key: 'this_week',
    value: 'This Week',
  },
  {
    key: 'this_month',
    value: 'This Month',
  },
  {
    key: 'custom',
    value: 'Custom',
  },
];

const TimerShortPushingInterval = 10000; // in milliseconds

const IdleUserTimeout = 100000; // in milliseconds

const WarningTimeout = 20; // in seconds

const CanShowExtraction = true;

const CanShowComparison = true;

const CanShowValidation = true;

const CanShowEnrichment = true;

const PAGE_OFFSET = 50;

const MergedType = 'Merged';

const UnknownType = 'unknown';

const Themes = {
  LIGHT: 'light',
  DARK: 'dark',
};

const RoleManagementHeaders = [
  {
    key: 'role', value: 'Role', isSortingAvailable: false,
  },
  {
    key: 'description', value: 'Description', isSortingAvailable: false,
  },
  {
    key: 'created_at', value: 'Created On', isSortingAvailable: true,
  },
  {
    key: 'updated_at', value: 'Modified On', isSortingAvailable: true,
  },
  {
    key: 'number_of_users', value: 'Number of Users', isSortingAvailable: false,
  },
  {
    key: 'action', value: 'Action', isSortingAvailable: false,
  },
];

const PermissionManagerHeaders = [
  { key: 'number', value: 'No.' },
  { key: 'pages', value: 'Pages' },
  { key: 'sections_functions', value: 'Sections/Functions' },
  { key: 'rights', value: 'Rights' },
];

const MergeDocument = {
  key: 'Merged',
  value: 'Merged Document',
};

const SubmissionAttachmentsHeader = [
  { key: 'document_name', value: 'Document Name' },
  { key: 'classification', value: 'Classification' },
  { key: 'confidence_score', value: 'Confidence Score' },
  { key: 'status', value: 'Status' },
  { key: 'actions', value: 'Actions' },
];

const ReadOnlyMode = false;

const HitlStatus = {
  HIDE: 'hide',
  DISABLE: 'disable',
  ENABLE: 'enable',
};

const notFound = 'not found';

const ComparisonSectionNames = {
  formNumber: 'Form number',
  NamedInsured: 'Named Insured',
};

const ComparisonColorCoding = {
  Matching: 'matching',
  NotMatching: 'not_matching',
  Neutral: 'neutral',
};

const zoomOptions = [
  { key: 'auto', value: 'Automatic Zoom' },
  { key: 'page-actual', value: 'Actual Size' },
  { key: 'page-fit', value: 'Page Fit' },
  { key: 'page-width', value: 'Page Width' },
  { key: '0.5', value: '50%' },
  { key: '0.75', value: '75%' },
  { key: '1', value: '100%' },
  { key: '1.25', value: '125%' },
  { key: '2', value: '200%' },
  { key: '3', value: '300%' },
  { key: '4', value: '400%' },
];

const VALID_FILE_TYPES = [
  'application/vnd.ms-excel',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/zip',
  'application/x-zip-compressed',
  'application/zip-compressed',
  'application/vnd.ms-outlook',
  'message/rfc822',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
];

const MAX_FILE_SIZE_MB = 100;

const routePermissions = {
  '/home/create-application': [Permissions.CREATE_APPLICATION],
  '/home/access-management': [Permissions.CREATE_ROLE, Permissions.CREATE_USER],
  '/home/insights-dashboard': [Permissions.VIEW_METRICS],
};

const UatUsers = [];

const summaryTooltipMapping = {
  client: ['#Client, #Policy+#Holder'],
  'group number': ['#Group'],
  'Effective Date': ['#Effective'],
  'Emp - New Hire - Starts On': ['#Enrollment, #Coverage, #Eligible '],
  'Emp - New Hire - #Days': ['#Enrollment, #Coverage'],
  'Emp - Rehire - Waiting Period': ['#Waiting, #Coverage'],
  'Emp - Status Change - Waiting Period': ['#Within+#Days'],
  'Emp - Eligible Classes': ['#Classes, #Class'],
  'Emp - Weekly Hours Needed': ['#hours, #work'],
  'Emp - Continuation': ['#continuation, #COBRA'],
  'Emp - Termination': ['#termination'],
  Dependent: ['#dependent'],
  'Dep - Domestic Partners Covered': ['#domestic + #Partner #legal + #spouse (If #Domestic + #Partner then Answer: Yes;If #Legal + #Spouse then Answer: No If both, then answer: Yes)'],
  'Dep - Spouse Rate (Vol Life)': ['Not Applicable'],
  'Dep - Child Max Age': ['#Child, #Dependent'],
  'Dep - Child Max Age (Conditional Age)': ['#Child, #Dependent, #State, #Resident'],
};

const fileExtensions = {
  pdf: 'pdf',
  xlsx: 'xlsx',
};

const TOTAL_ROWS_TO_DISPLAY = 20;
const ExtractionEmailSubjectOptions = [
  {
    key: 'List of Entities to be Validated with the Carrier',
    value: 'List of Entities to be Validated with the Carrier',
  },
];

const ExtractionDropdown = {
  pagination: 'pagination',
  summary: 'summary',
};

// this is used in draw bounding box feature
const BoundingBoxType = {
  NONE: 'none',
  TABLE: 'table',
  ROW: 'row',
  COLUMN: 'column',
  CELL: 'cell',
};

export {
  workflowTabs,
  ApplicationDashboardHeaders,
  AuditLogDashboardHeaders,
  AuditCategories,
  AppDashboardFilters,
  AttachmentHeaders,
  extractionScreenEntityHeader,
  bulkOperationName,
  KeyBoardKeys,
  OperationalMessages,
  pageSizeOptions,
  Permissions,
  ApplicationStatus,
  AttachmentStatusCount,
  SilentRefreshStartStatuses,
  SilentRefreshStopStatuses,
  SilentRefreshTimeout,
  SilentRefreshMaxRetries,
  ExportDocumentDropdownOptions,
  TimeFilterOptions,
  AttachmentStatus,
  TimerShortPushingInterval,
  IdleUserTimeout,
  WarningTimeout,
  MergedType,
  UnknownType,
  Themes,
  CanShowExtraction,
  CanShowComparison,
  CanShowEnrichment,
  CanShowValidation,
  PAGE_OFFSET,
  RoleManagementHeaders,
  PermissionManagerHeaders,
  MergeDocument,
  SubmissionAttachmentsHeader,
  ReadOnlyMode,
  HitlStatus,
  notFound,
  ComparisonSectionNames,
  ComparisonColorCoding,
  zoomOptions,
  VALID_FILE_TYPES,
  MAX_FILE_SIZE_MB,
  routePermissions,
  UatUsers,
  ExportEntitiesDocumentDropdownOptions,
  ExportSummaryDocumentDropdownOptions,
  ExportComparisonDropdownOptions,
  summaryTooltipMapping,
  ExportTableDocumentDropdownOptions,
  fileExtensions,
  TOTAL_ROWS_TO_DISPLAY,
  BoundingBoxType,
  ExtractionDropdown,
  ExtractionEmailSubjectOptions,
};
