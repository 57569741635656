import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';
import useAxios from '../../../hooks/useAxios';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import {
  IdleUserTimeout,
  ApplicationStatus,
  TimerShortPushingInterval,
  WarningTimeout,
  Permissions,
} from '../../../services/constants/constants';
import { formattedReviewSeconds, formattedTime, isEmpty } from '../../../services/utils/Helper';
import useTabChange from '../../../hooks/useTabChange';
import {
  setIsTimerPaused,
} from '../../../redux/actions/timerActions';
import ReviewCompletedIcon from '../../../assets/reviewCompletedIcon.svg';
import ButtonFilled from '../../atoms/button/ButtonFilled';
import Loader from '../loader/Loader';
import classes from './Timer.module.css';
import IconButton from '../icon-button/IconButton';
import { Icon } from '../../atoms/SvgSprites/Icon';
import { ApplicationService } from '../../../services/constants/endpoints';
import { useSnackbar } from '../../../context/SnackbarContext';
import Toast from '../../../services/constants/toasters';
import usePermissions from '../../../hooks/usePermissions';
import CustomizedPopover from '../../atoms/popover/Popover';
import TextButton from '../../atoms/button/TextButton';

function Timer(props) {
  const {
    applicationId, fetchApplication, showCompleteReview,
  } = props;

  const { openedApplication } = useSelector((state) => state.applications);
  const dispatch = useDispatch();

  const { isUserAssigned, checkPermission } = usePermissions();

  const [completingLoader, setCompletingLoader] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [time, setTime] = useState(0);
  const [warningTime, setWarningTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [idleConfirmPopupOpen, setIdleConfirmPopupOpen] = useState(false);
  const [resetConfirmPopupOpen, setResetConfirmPopupOpen] = useState(false);
  const [resetTimerAnchor, setResetTimerAnchor] = useState(null);
  const [completeReviewAnchor, setCompleteReviewAnchor] = useState(null);
  const [isCompleteReview, setIsCompleteReview] = useState(false);
  const timeRef = useRef(time);
  const warningTimeRef = useRef(warningTime);
  const timerElementRef = useRef(null);
  const idleTimeoutInterval = useRef(null);
  const hardPaused = useRef(true);
  const firstRender = useRef(true);

  const axiosPrivate = useAxios();
  const { isUserIdle, resetTimeout } = useIdleTimeout(IdleUserTimeout);
  const isTabChanged = useTabChange();
  const { openSnackbar } = useSnackbar();

  const handleResetTimerAnchor = (event) => {
    setResetTimerAnchor(event.currentTarget);
  };

  const handleCompleteReviewAnchor = (event, status) => {
    if (status === 'reviewed') setIsCompleteReview(true);
    else setIsCompleteReview(false);
    setCompleteReviewAnchor(event.currentTarget);
  };

  const fetchInitialTimerValue = (config) => {
    axiosPrivate.get(`${ApplicationService.UPDATE_APPLICATION(applicationId)}`, config)
      .then((response) => {
        setLoading(false);
        setTime(response.data.data.review_time);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const saveTimeToDB = (explicitTime = undefined) => {
    if (!applicationId) return;

    const timeValue = typeof explicitTime !== 'undefined' ? explicitTime : timeRef.current;
    const payload = {
      field: 'review_time',
      value: timeValue,
    };

    axiosPrivate.patch(`${ApplicationService.UPDATE_APPLICATION(applicationId)}`, payload)
      .then(() => { })
      .catch(() => { });
  };

  const startTimer = () => {
    setIsTimerActive(true);
    dispatch(setIsTimerPaused(false));
    hardPaused.current = false;
  };

  const pauseTimer = () => {
    setIsTimerActive(false);
    saveTimeToDB();
    dispatch(setIsTimerPaused(true));
  };

  const handleReset = (event) => {
    setResetConfirmPopupOpen(true);
    pauseTimer();
    handleResetTimerAnchor(event);
  };

  const resetTimer = () => {
    setIsTimerActive(false);
    setTime(0);
    saveTimeToDB(0);
    hardPaused.current = true;
  };

  const handleWarningTimer = () => {
    if (warningTimeRef.current >= WarningTimeout) {
      clearInterval(idleTimeoutInterval.current);
      setIdleConfirmPopupOpen(false);
      setIsTimerActive(false);
      dispatch(setIsTimerPaused(true));

      if ((timeRef.current - WarningTimeout - IdleUserTimeout / 1000) >= 0) {
        saveTimeToDB(timeRef.current - WarningTimeout - IdleUserTimeout / 1000);
        setTime((prevState) => (prevState - WarningTimeout - IdleUserTimeout / 1000));
      } else {
        saveTimeToDB(0);
        setTime(0);
      }

      setWarningTime(0);
      hardPaused.current = true;
    } else {
      setWarningTime((prevState) => prevState + 1);
    }
  };

  const handleUserStillHere = () => {
    setIdleConfirmPopupOpen(false);
    clearInterval(idleTimeoutInterval.current);
    setWarningTime(0);
  };

  const completeReview = () => {
    const payload = {
      field: 'status',
      value: isCompleteReview ? 'Reviewed' : 'extraction_under_review',
    };

    setCompletingLoader(true);
    axiosPrivate.patch(`${ApplicationService.UPDATE_APPLICATION(applicationId)}`, payload)
      .then(async () => {
        fetchApplication();
        setCompletingLoader(false);
        openSnackbar('success', isCompleteReview ? Toast.REVIEW_COMPLETED_SUCCESS : Toast.REVIEW_RESTARTED_SUCCESS);
      })
      .catch((error) => {
        setCompletingLoader(false);
        openSnackbar('error', error?.response?.data?.message);
      });
  };

  const getPacketTime = () => {
    const value = formattedTime(time);

    return (
      <>
        <span className={`h5Medium ${classes.digits}`}>
          {value[0]}
          :
        </span>
        <span className={`h5Medium ${classes.digits}`}>
          {value[1]}
          :
        </span>
        <span className={`h5Medium ${classes.digits}`}>
          {value[2]}
        </span>
      </>
    );
  };

  const getInfoMsg = () => {
    let msg = '';
    if (checkPermission(Permissions.EDIT_EXTRACTION)) {
      if (isUserAssigned()) {
        if (time === 0) {
          msg = 'Please start the timer to begin the review process';
        } else {
          msg = 'Please resume the timer to continue the review process';
        }
      } else {
        msg = 'The application must be assigned to you in order to do the review process';
      }
    } else {
      msg = 'Your role lacks the necessary permissions to make edits';
    }

    return msg;
  };

  useEffect(() => {
    if (isUserIdle && isTimerActive && !isTabChanged && !idleConfirmPopupOpen) {
      setIdleConfirmPopupOpen(true);
      idleTimeoutInterval.current = setInterval(handleWarningTimer, 1000);
    }
  }, [isUserIdle]);

  useEffect(() => {
    warningTimeRef.current = warningTime;
  }, [warningTime]);

  useEffect(() => {
    let timeInterval = null;
    let shortPushInterval = null;

    if (isTimerActive) {
      shortPushInterval = setInterval(saveTimeToDB, TimerShortPushingInterval);
      timeInterval = setInterval(() => {
        setTime((prevState) => prevState + 1);
      }, 1000);
    } else {
      clearInterval(timeInterval);
      clearInterval(shortPushInterval);
    }

    return () => {
      clearInterval(timeInterval);
      clearInterval(shortPushInterval);
    };
  }, [isTimerActive]);

  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (isTabChanged) {
      if (isTimerActive) {
        if (idleConfirmPopupOpen) {
          setIdleConfirmPopupOpen(false);
          clearInterval(idleTimeoutInterval.current);
          setWarningTime(0);
          resetTimeout();
        }
        pauseTimer();
      }
    } else if (typeof isTabChanged !== 'undefined') {
      setLoading(true);
      setTimeout(() => {
        fetchInitialTimerValue();
      }, 1000);
    }
  }, [isTabChanged]);

  useEffect(() => {
    if (openedApplication.review_time && firstRender.current) {
      setTime(openedApplication.review_time);
      firstRender.current = false;
    }

    return () => {
      if (!firstRender.current) {
        saveTimeToDB();
        clearInterval(idleTimeoutInterval.current);
        dispatch(setIsTimerPaused(true));
      }
    };
  }, [openedApplication]);

  return (
    <>
      <Loader loading={completingLoader} />
      {!isEmpty(openedApplication) && (
        openedApplication?.status?.toLowerCase() === ApplicationStatus.REVIEWED.toLowerCase() ? (
          <div className={classes.reviewCompleteContainer}>
            <div>
              <div className={classes.reviewCompleteMsg}>
                <img src={ReviewCompletedIcon} alt="check" />
                <span className={`h3Medium ${classes.reviewCompleteText}`}>Review Completed!</span>
              </div>
              <div className={classes.reviewTime}>
                {formattedReviewSeconds(openedApplication.review_time)}
              </div>
            </div>
            <div>
              <ButtonFilled
                text="Restart Review"
                height="1.75rem"
                onClick={(event) => handleCompleteReviewAnchor(event, 'extraction_under_review')}
                borderRadius="4px 4px 4px 4px"
                disabled={!isUserAssigned()
                  || !checkPermission(Permissions.EDIT_EXTRACTION)}
                defaultClass="h5Regular"
                width="max-content"
              />
            </div>
          </div>
        ) : (
          <div className={classes.timerContainer}>
            {!isTimerActive
              && !resetConfirmPopupOpen
              && openedApplication?.status
                ?.toLowerCase() !== ApplicationStatus.REVIEWED.toLowerCase()
              && (
                <>
                  <div className={classes.infoMsg}>
                    <Icon
                      name="warningIcon"
                      height={18}
                      width={18}
                      color="warning"
                      classes={classes.infoIconStyle}
                    />
                    <span className={`h5Regular ${classes.infoMsgText}`}>
                      {getInfoMsg()}
                    </span>
                  </div>
                  <div className={classes.arrowRight} />
                </>
              )}
            <div className={classes.timer} ref={timerElementRef}>
              <div className={classes.timerControls}>
                <IconButton
                  iconName="timerReset"
                  iconWidth={17}
                  iconHeight={17}
                  handleClick={handleReset}
                  isDisabled={time === 0 || !openedApplication.allDocumentsLoaded
                    || loading || !isUserAssigned()
                    || !checkPermission(Permissions.EDIT_EXTRACTION)}
                  btnClasses={classes.buttonStyle}
                />
                {isTimerActive ? (
                  <IconButton
                    iconName="timerPause"
                    iconWidth={21}
                    iconHeight={21}
                    iconColor="yellowDefault"
                    handleClick={() => { hardPaused.current = true; pauseTimer(); }}
                    isDisabled={!(isTimerActive
                      && openedApplication.allDocumentsLoaded && !loading)
                      || !isUserAssigned()
                      || !checkPermission(Permissions.EDIT_EXTRACTION)}
                    iconClasses={classes.iconBtnStyle}
                    btnClasses={`${classes.buttonStyle} ${classes.timerBtns}`}
                  />
                ) : (
                  <IconButton
                    iconName="timerStart"
                    iconWidth={21}
                    iconHeight={21}
                    iconColor="greenDefault"
                    handleClick={startTimer}
                    isDisabled={isTimerActive || !openedApplication.allDocumentsLoaded
                      || loading || !isUserAssigned()
                      || !checkPermission(Permissions.EDIT_EXTRACTION)}
                    iconClasses={classes.iconBtnStyle}
                    btnClasses={`${classes.buttonStyle} ${classes.timerBtns}`}
                  />
                )}
              </div>
              {loading
                ? (
                  <p className={`h5Medium ${classes.loadingStyle}`}>Loading...</p>
                )
                : (
                  <div className={`h5Medium ${classes.timeStyle}`}>
                    {getPacketTime()}
                  </div>
                )}
              <CustomizedPopover
                anchorEl={resetTimerAnchor}
                open={Boolean(resetTimerAnchor)}
                handleClose={() => {
                  setResetTimerAnchor(null);
                  setResetConfirmPopupOpen(false);
                }}
                content={(
                  <div className={classes.confirmPopoverActionContainer}>
                    <div className={classes.confirmPopoverActionLabel}>
                      <p className={classes.confirmPopupHeader}>Confirm Reset</p>
                      <p className={`h3Regular ${classes.confirmPopupHeader}`}>Are you sure you want to reset the timer ?</p>
                    </div>
                    <div className={classes.confirmPopoverAction}>
                      <TextButton
                        text="Cancel"
                        onClick={() => {
                          if (!hardPaused.current) startTimer();
                          setResetTimerAnchor(null);
                          setResetConfirmPopupOpen(false);
                        }}
                      />
                      <ButtonFilled
                        height="2rem"
                        text="Confirm"
                        onClick={() => {
                          resetTimer();
                          setResetTimerAnchor(null);
                          setResetConfirmPopupOpen(false);
                        }}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <Popover
              open={idleConfirmPopupOpen}
              anchorEl={timerElementRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className={classes.popupStyle}>
                <p className="h3Medium">Are you still there?</p>
                <p className={`h3Regular ${classes.popupWarningText}`}>
                  If not we&apos;ll pause this session in
                  {' '}
                  <span className={`h3Medium ${classes.popupWarningText}`}>{WarningTimeout - warningTime}</span>
                </p>
                <div className={classes.stillHereStyle}>
                  <ButtonFilled
                    text="I'm Here"
                    height="2rem"
                    onClick={handleUserStillHere}
                  />
                </div>
              </div>
            </Popover>
            {showCompleteReview
              && (
                <ButtonFilled
                  text="Complete Review"
                  height="1.75rem"
                  onClick={(event) => handleCompleteReviewAnchor(event, 'reviewed')}
                  borderRadius="0px 4px 4px 0px"
                  disabled={time === 0 || !isUserAssigned()
                    || !checkPermission(Permissions.EDIT_EXTRACTION)}
                  defaultClass="h5Regular"
                  width="max-content"
                />
              )}
          </div>
        )
      )}

      <CustomizedPopover
        anchorEl={completeReviewAnchor}
        open={Boolean(completeReviewAnchor)}
        handleClose={() => setCompleteReviewAnchor(null)}
        content={(
          <div className={classes.confirmPopoverActionContainer}>
            <div className={classes.confirmPopoverActionLabel}>
              <p className={`h3Regular ${classes.confirmPopupHeader}`}>
                Are you sure you want to
                {' '}
                {isCompleteReview ? 'complete' : 'restart'}
                {' '}
                the review ?
              </p>
            </div>
            <div className={classes.confirmPopoverAction}>
              <TextButton
                text="Cancel"
                onClick={() => setCompleteReviewAnchor(null)}
              />
              <ButtonFilled
                height="2rem"
                text="Confirm"
                onClick={() => {
                  pauseTimer();
                  completeReview();
                  setCompleteReviewAnchor(null);
                }}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

Timer.propTypes = {
  applicationId: string.isRequired,
  fetchApplication: func.isRequired,
  showCompleteReview: bool,
};

Timer.defaultProps = {
  showCompleteReview: true,
};

export default Timer;
